import axios from 'axios'

const api = axios.create({
    baseURL: process.env.VUE_APP_WEB_API ?? 'http://localhost:3010/booking-web',
    headers: {
        'Content-Type': 'application/json',
    },
})

class WebService {
    getBooking(number) {
        return api.get(`/booking-web/bookings/${number}`)
    }

    getTimeWindows(id, start, stop) {
        return api.get(`/booking-web/bookings/${id}/time-windows?start=${start.toISOString()}&stop=${stop.toISOString()}`)
    }

    updateBookingTimeWindow(id, date, index, contactMe, contactMeText) {
        return api.put(`/booking-web/bookings/${id}`, { date, index, contactMe, contactMeText })
    }

    updateContactInfo(id, user_info) {
        return api.put(`/booking-web/bookings/${id}/contact`, user_info)
    }

    updateBookingContactMe(id, contactMeText) {
        return api.put(`/booking-web/bookings/${id}/contact_me`, { contactMeText })
    }

    sendConfirmation(id) {
        return api.post(`/booking-web/bookings/${id}/confirmation`)
    }
}

export default new WebService()