<template>
    <div class="container" style="text-align: center">
        <a href="https://matarkontroll.se" target="_blank"><img class="logo" src="/matarkontroll.png" alt="MÄTARKONTROLL"/></a>
        <div v-if="booking.id !== null && (rebookingDone || contactRequestDone)">
            <h2>{{ rebookingDone ? 'OMBOKNING KLAR' : 'TACK FÖR BESÖKET' }}</h2>
            <div class="mt-3" v-show="contactRequestDone">
                Vi kontaktar er inom kort.
            </div>
            <div class="mt-3" v-show="rebookingDone">
                <div class="booking-date-title">
                    Ny tid:
                </div>
                <div class="booking-date">
                    {{ new Date(booking.date).toLocaleDateString('sv') }}
                    {{ hours[booking.index] }}-{{ hours[booking.index + 1] }}
                </div>
                <form>
                    <input type="checkbox" class="form-check-input" v-model="sendEmail" id="emailCheckBox" name="emailCheckBox">
                    <label for="emailCheckBox" class="form-label">&nbsp;Skicka ett e-postmeddelande till {{ booking.user_info.email }} med en bekräftelse av den nya tiden vid utloggning.</label>
                </form>
            </div>
            <button class="mt-3 btn btn-primary" @click="onLogout(true)">Logga ut</button>
        </div>
        <div v-else-if="booking.id !== null">
            <h2>VÄLJ NY TID</h2>
            <div>
                <div class="booking-title">
                    {{ booking.description }} {{ booking.address }}
                </div>
                <div class="booking-date-title">
                    Nuvarande tid:
                </div>
                <div class="booking-date">
                    {{ new Date(booking.date).toLocaleDateString('sv') }}
                    {{ hours[booking.index] }}-{{ hours[booking.index + 1] }}
                </div>
                <div class="week-row">
                    <button class="btn btn-outline-secondary btn-small" @click="onPreviousWeek" :disabled="year === minYear && week === minWeek" title="Föregående vecka">&lt;</button>
                    <span class="ms-3 me-3 week">VECKA {{ week }} {{ year }}</span>
                    <button class="btn btn-outline-secondary" @click="onNextWeek" title="Nästa vecka">&gt;</button>
                </div>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-2">mån</div>
                    <div class="col-2">tis</div>
                    <div class="col-2">ons</div>
                    <div class="col-2">tor</div>
                    <div class="col-2">fre</div>
                </div>
                <div class="row mb-1 text-muted small">
                    <div class="col-2"></div>
                    <div class="col-2">{{ days[0].label }}</div>
                    <div class="col-2">{{ days[1].label }}</div>
                    <div class="col-2">{{ days[2].label }}</div>
                    <div class="col-2">{{ days[3].label }}</div>
                    <div class="col-2">{{ days[4].label }}</div>
                </div>
                <div class="row" v-for="slot in 4">
                    <div class="col-2" style="height: 3rem; text-align: right">
                        <div style="top:50%; margin-top:-10px;">{{ new String(hours[slot - 1]) }}</div>
                    </div>
                    <div v-for="day in 5" :class="'col-2 ' + getStyle(days[day - 1].windows[slot - 1])" @click="onTimeWindowClick(day - 1, slot - 1)">
                        {{ getCalendarText(days[day - 1].windows[slot - 1]) }}
                    </div> 
                </div>
                <div class="row">
                    <div class="col-2" style="height: 3rem; text-align: right">
                        <div style="top:50%; margin-top:-15px">{{ new String(hours[4]) }}</div>
                    </div>
                    <div class="col-10 calendar-row">
                        &nbsp;
                    </div>
                </div>
            </div>
            <div class="mb-3" v-show="showContactMe">
                <input id="contactMeInput" type="checkbox" class="form-check-input" v-model="contactMe"/>
                <label for="contactMeInput" class="form-label ms-2">{{ contactMeLabel }}</label>
                <div v-show="showContactMe && contactMe">
                    <textarea id="contactMeText" class="form-control" style="width: 50%; margin-left: auto; margin-right: auto" rows="2" placeholder="Skriv en kort beskrivning om vad vi kan hjälpa dig med" v-model="contactMeText"></textarea>
                </div>
                <div class="text-danger mt-1" v-show="contactMeTextMissing">Vänligen fyll i fältet ovan</div>
            </div>
            <button class="btn btn-secondary me-2" @click="onLogout(false)">Avbryt</button>
            <button class="btn btn-primary ms-2" @click="onContinue()" :disabled="!contactMe && selectedDate === null">Fortsätt</button>
            <div class="mt-3">
                <button class="btn btn-outline-primary" @click="onEditContacts(false)">Ändra kontaktuppgifter</button>
            </div>
        </div>
        <div v-else>
            <h2>OMBOKNING AV TID</h2>
            <div class="mb-3 mt-3 row">
                <div class="col-4"></div>
                <div class="col-4">
                    <label for="username" class="form-label">Bokningsnummer</label>
                    <input type="text" class="form-control" v-model="number" id="numberInput" name="number"/>
                    <div class="text-danger mt-3">{{ loginErrorText }}</div>
                </div>
            </div>
            <button class="btn btn-primary" @click="onLogin">Logga in</button>
        </div>
        <div class="modal" id="confirmModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Bekräfta kontaktuppgifter</h4>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="confirm-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                                </svg>
                            </div>
                            <div>{{ booking.user_info.name }}</div>
                            <div>{{ booking.user_info.address }}</div>
                            <div>{{ booking.user_info.zip }} {{ booking.user_info.city }}</div>
                            <div class="confirm-icon">@</div>
                            <div>{{ booking.user_info.email }}</div>
                            <div class="confirm-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                                </svg>
                            </div>
                            <div>{{ booking.user_info.phone }}</div>
                            <div class="mt-3">
                                <button type="button" class="btn btn-outline-primary" @click="onEditContacts(true)">Ändra kontaktuppgifter</button>
                            </div>
                        </div>
                        <div class="mt-3">
                            <form>
                                <input type="checkbox" class="form-check-input" v-model="confirmContactInfo" id="confirmCheckBox" name="confirmCheckBox">
                                <label for="confirmCheckBox" class="form-label">&nbsp;Jag bekräftar att kontaktuppgifterna ovan är aktuella</label>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="confirmModal.hide()">Gå tillbaka</button>
                        <button type="button" class="btn btn-primary" @click="onDone" :disabled="!confirmContactInfo">{{ selectedDate ? 'Genomför ombokning' : 'Fortsätt' }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">{{ supplementContactInfo ? 'Vänligen komplettera kontaktuppgifter' : 'Ändra kontaktuppgifter' }}</h4>
                    </div>
                    <div class="modal-body" style="text-align: left">
                        <form ref="form">
                            <div class="mb-3">
                                <label for="name" class="form-label">Namn</label>
                                <input type="text" class="form-control" v-model="booking.user_info.name" id="name" name="name" />
                                <div class="text-danger">{{ nameErrorText }}</div>
                            </div>
                            <div class="mb-3">
                                <label for="address" class="form-label">Gatuadress</label>
                                <input type="text" class="form-control" v-model="booking.user_info.address" id="address" name="address" />
                                <div class="text-danger">{{ addressErrorText }}</div>
                            </div>
                            <div class="mb-3">
                                <label for="zip" class="form-label">Postnummer</label>
                                <input type="text" class="form-control" v-model="booking.user_info.zip" id="zip" name="zip" />
                                <div class="text-danger">{{ zipErrorText }}</div>
                            </div>
                            <div class="mb-3">
                                <label for="city" class="form-label">Ort</label>
                                <input type="text" class="form-control" v-model="booking.user_info.city" id="city" name="city" />
                                <div class="text-danger">{{ cityErrorText }}</div>
                            </div>
                            <div class="mb-3">
                                <label for="email" class="form-label">E-postadress</label>
                                <input type="text" class="form-control" v-model="booking.user_info.email" id="email" name="email" />
                                <div class="text-danger">{{ emailErrorText }}</div>
                            </div>
                            <div class="mb-3">
                                <label for="phone" class="form-label">Telefonnummer</label>
                                <input type="text" class="form-control" v-model="booking.user_info.phone" id="phone" name="phone" />
                                <div class="text-danger">{{ phoneErrorText }}</div>
                            </div>
                        </form>
                        <div class="alert altert-danger" v-show="saveErrorText">{{ saveErrorText }}</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="onCancelEdit()">Avbryt</button>
                        <button type="button" class="btn btn-primary" @click="onContactsSubmit()">Spara</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="errorModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Ett fel uppstod</h4>
                    </div>
                    <div class="modal-body" style="text-align: left">
                        <div>{{ submitErrorText }}</div>
                        <div class="mt-3">För hjälp, kontakta oss på 08-425 056 39.</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="errorModal.hide()">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { Modal } from 'bootstrap'
import webService from './webService'
import dateTime from './dateTimeUtils'

// Modals
const confirmModal = ref(null)
const editModal = ref(null)
const errorModal = ref(null)
// Form data
const number = ref('')
const contactMe = ref(false)
const contactMeText = ref('')
const contactMeTextMissing = ref(false)
const selectedDate = ref(null)
const selectedIndex = ref(null)
const sendEmail = ref(false)
const confirmContactInfo = ref(false)
// Reactive data
const year = ref(null)
const week = ref(null)
const rebookingDone = ref(false)
const contactRequestDone = ref(false)
// Error messages
const nameErrorText = ref(null)
const addressErrorText = ref(null)
const zipErrorText = ref(null)
const cityErrorText = ref(null)
const emailErrorText = ref(null)
const phoneErrorText = ref(null)
const saveErrorText = ref(null)
const loginErrorText = ref(null)
const submitErrorText = ref(null)
// Variables
let confirmAfterSave = false
let confirmAfterCancel = false
let supplementContactInfo = false
let weeksInYear = dateTime.getWeeksInYear(new Date().getFullYear())
let showContactMe = false
let contactMeLabel = ''
// Constants
const minYear = new Date().getFullYear()
const minWeek = dateTime.getWeekNumber(new Date())
const hours = [ '07:00', '10:00', '13:00', '16:00', '20:00' ]
// Calendar data
const days = reactive({
    0: { label: '', date: null, windows: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 } },
    1: { label: '', date: null, windows: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 } },
    2: { label: '', date: null, windows: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 } },
    3: { label: '', date: null, windows: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 } },
    4: { label: '', date: null, windows: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 } },
})
// Booking data
let booking = reactive({
    id: null,
    description: '',
    address: '',
    date: '',
    index: 0,
    user_info: {
        name: '',
        address: '',
        zip: '',
        city: '',
        email: '',
        phone: ''
    },
})
// Query parameters
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
})

// Check if booking number is in query parameters, and if so, log in
if (params.n) {
    number.value = params.n
    onLogin()
}

// Initialize modals when mounting
onMounted(() => {
    confirmModal.value = new Modal(document.getElementById('confirmModal'))
    editModal.value = new Modal(document.getElementById('editModal'))
    errorModal.value = new Modal(document.getElementById('errorModal'))
})

// Event handlers

function onPreviousWeek() {
    if (year.value === minYear && week.value === minWeek) {
        return
    }
    week.value--
    if (week.value < 1) {
        year.value--
        weeksInYear = dateTime.getWeeksInYear(year.value)
        week.value = weeksInYear
    }
    updateTimeWindows()
}

function onNextWeek() {
    week.value++
    if (week.value > weeksInYear) {
        year.value++
        week.value = 1
        weeksInYear = dateTime.getWeeksInYear(year.value)
    }
    updateTimeWindows()
}

function onContinue() {
    contactMeTextMissing.value = false
    if (contactMe.value && contactMeText.value.trim() === '') {
        contactMeTextMissing.value = true
        return
    }

    if (!validateContacts()) {
        confirmAfterSave = true
        confirmAfterCancel = false
        supplementContactInfo = true
        editModal.value.show()
        return
    }

    confirmContactInfo.value = false
    confirmModal.value.show()
}

function onEditContacts(confirm) {
    if (confirm) {
        confirmModal.value.hide()
    }
    confirmAfterSave = confirm
    confirmAfterCancel = confirm
    nameErrorText.value = null
    addressErrorText.value = null
    zipErrorText.value = null
    cityErrorText.value = null
    emailErrorText.value = null
    phoneErrorText.value = null
    saveErrorText.value = null
    confirmContactInfo.value = false
    editModal.value.show()
}

function onCancelEdit() {
    supplementContactInfo = false
    editModal.value.hide()
    if (confirmAfterCancel) {
        confirmModal.value.show()
    }
}

async function onContactsSubmit() {
    if (!validateContacts()) {
        return
    }
    
    supplementContactInfo = false

    saveErrorText.value = null

    const contact = {
        name: booking.user_info.name,
        address: booking.user_info.address,
        zip: booking.user_info.zip,
        city: booking.user_info.city,
        email: booking.user_info.email,
        phone: booking.user_info.phone
    }

    webService.updateContactInfo(booking.id, contact).then(response => {
        editModal.value.hide()
        if (confirmAfterSave) {
            confirmContactInfo.value = false
            confirmModal.value.show()
        }
    }).catch(err => {
        if (err.code === 'ERR_NETWORK') {
            saveErrorText.value = 'Nätverksfel, vänligen kontrollera din internetanslutning.'
            return
        }
        saveErrorText.value = err.response.data
    })
}

function onDone() {
    if (selectedDate.value === null && contactMe.value) {
        webService.updateBookingContactMe(booking.id, contactMeText.value).then(_response => {
            contactRequestDone.value = true
            confirmModal.value.hide()
        }).catch(err => {
            confirmModal.value.hide()
            if (err.code === 'ERR_NETWORK') {
                submitErrorText.value = 'Nätverksfel, vänligen kontrollera din internetanslutning.'
                errorModal.value.show()
                return
            }
            submitErrorText.value = err.response.data
            errorModal.value.show()
        })
    } else if (selectedDate.value !== null && selectedIndex.value !== null){
        webService.updateBookingTimeWindow(booking.id, selectedDate.value, selectedIndex.value, contactMe.value, contactMeText.value).then(_response => {
            confirmModal.value.hide()
            rebookingDone.value = true
            contactRequestDone.value = contactMe.value
            booking.date = selectedDate.value
            booking.index = selectedIndex.value
            selectedDate.value = null
            selectedIndex.value = null
        }).catch(err => {
            confirmModal.value.hide()
            if (err.code === 'ERR_NETWORK') {
                submitErrorText.value = 'Nätverksfel, vänligen kontrollera din internetanslutning.'
                errorModal.value.show()
                return
            }
            submitErrorText.value = err.response.data
            errorModal.value.show()
        })
    }
}

function onTimeWindowClick(day, index) {
    if (days[day].windows[index] === 1 || contactMe.value && days[day].windows[index] === 2) {
        if (selectedDate.value != null && selectedIndex.value != null) {
            for (let i = 0; i < 5; i++) {
                if (selectedDate.value === days[i].date) {
                    days[i].windows[selectedIndex.value] = 1
                    break    
                }            
            }
        }
        if (days[day].windows[index] === 1) {
            days[day].windows[index] = 3
            selectedDate.value = days[day].date
            selectedIndex.value = index
        } else {
            selectedDate.value = null
            selectedIndex.value = null
        }
    }
}

function onLogout(confirm) {
    if (confirm && sendEmail.value) {
        webService.sendConfirmation(booking.id)
    }
    rebookingDone.value = false
    contactRequestDone.value = false
    sendEmail.value = false
    booking.id = null
}

function onLogin() {
    rebookingDone.value = false
    contactRequestDone.value = false
    selectedDate.value = null
    selectedIndex.value = null
    contactMe.value = false

    const nr = number.value
    let trimmedNumber = ''

    for (let i = 0; i < nr.length; i++) {
        if (isNaN(nr[i])) {
            if (nr[i] === ' ' || nr[i] === '-') {
                continue
            }
            loginErrorText.value = 'Bokningsnummer får endast innehålla siffror och bindestreck'
            return
        } else {
            trimmedNumber += nr[i]
        }
    }

    if (trimmedNumber.length !== 12) {
        loginErrorText.value = 'Bokningsnummer måste vara 12 siffror långt'
        return
    }

    webService.getBooking(trimmedNumber).then(response => {
        booking.id = response.data.id
        booking.description = response.data.description
        booking.address = response.data.location_address
        booking.date = response.data.date
        booking.index = response.data.index
        booking.user_info.name = response.data.user_info.name
        booking.user_info.address = response.data.user_info.address
        booking.user_info.zip = response.data.user_info.zip
        booking.user_info.city = response.data.user_info.city
        booking.user_info.email = response.data.user_info.email
        booking.user_info.phone = response.data.user_info.phone
        showContactMe = response.data.contact_me_checkbox
        contactMeLabel = response.data.contact_me_text

        const bookedDate = new Date(booking.date)
        year.value = bookedDate.getFullYear()
        week.value = dateTime.getWeekNumber(bookedDate)
        weeksInYear = dateTime.getWeeksInYear(year.value)

        updateTimeWindows(response.data.id)
    }).catch(err => {
        if (err.code === 'ERR_NETWORK') {
            loginErrorText.value = 'Nätverksfel, vänligen kontrollera din internetanslutning.'
            return
        }

        if (err.response.status === 404) {
            loginErrorText.value = 'Angivet bokningsnummer hittades inte. Vänligen kontrollera att det är korrekt.'
        } else if (err.response.status === 400) {
            loginErrorText.value = 'Bokningen är inte längre tillgänglig för ombokning då är mindre än två dagar kvar. Vänligen kontakta oss för hjälp.'
        } else {
            loginErrorText.value = 'Ett oväntat fel uppstod. Vänligen kontakta oss för hjälp.'
        }
    })
}

// Validators

function validateContacts() {
    nameErrorText.value = booking.user_info.name.trim() === '' ? 'Namn saknas' : null
    addressErrorText.value = booking.user_info.address.trim() === '' ? 'Gatuadress saknas' : null
    zipErrorText.value = validateZip(booking.user_info.zip)
    cityErrorText.value = booking.user_info.city.trim() === '' ? 'Ort saknas' : null
    emailErrorText.value = validateEmail(booking.user_info.email)
    phoneErrorText.value = validatePhone(booking.user_info.phone)

    if (nameErrorText.value || addressErrorText.value || zipErrorText.value || cityErrorText.value || emailErrorText.value || phoneErrorText.value) {
        return false
    }

    return true
}

function validateZip(zip) {
    if (zip == null || zip.trim() === '') {
        return 'Postnummer saknas'
    }
    let count = 0
    for (let i = 0; i < zip.length; i++) {
        if (zip[i] === ' ') {
            continue
        } else if (isNaN(zip[i])) {
            return 'Postnummer innehåller ogiltiga tecken'
        }
        count++
    }
    return count === 5 ? null : 'Postnummer måste vara 5 siffror långt'
}

function validateEmail(email) {
    if (email == null || email.trim() === '') {
        return 'E-postadress saknas'
    }
    const re = /\S+@\S+\.\S+/
    return re.test(email) ? null : 'Ogiltig e-postadress'
}

function validatePhone(phone) {
    if (phone == null || phone.trim() === '') {
        return 'Telefonnummer saknas'
    }
    let count = 0
    for (let i = 0; i < phone.length; i++) {
        if (i === 0 && phone[i] === '+') {
            continue
        } else if (phone[i] === ' ' || phone[i] === '-') {
            continue
        } else if (isNaN(phone[i])) {
            return 'Telefonnummer inneåller ogiltiga tecken'
        }
        count++
    }
    return count >= 8 ? null : 'Telefonnummer måste vara minst 8 siffror långt'
}

// Utility functions

async function updateTimeWindows() {
    if (booking.id === null) {
        return
    }
    const monday = dateTime.getMonday(year.value, week.value)
    const nextMonday = dateTime.addDays(monday, 7)
    const timeWindows = (await webService.getTimeWindows(booking.id, monday, nextMonday)).data

    // Clear all windows
    for (let day = 0; day < 5; day++) {
        days[day].date = dateTime.addDays(monday, day)
        days[day].label = days[day].date.getDate() + ' ' + dateTime.getMonthString(days[day].date.getMonth())
        for (let index = 0; index < 4; index++) {
            days[day].windows[index] = 0
        }
    }

    // Set available windows
    for (const window of timeWindows) {
        const day = new Date(window.date).getDay()
        const index = new Number(window.index)
        days[day - 1].windows[index] = window.is_available ? 1 : 0
    }

    // Set current booking window
    for (let day = 0; day < 5; day++) {
        if (days[day].date.toISOString() === booking.date) {
            days[day].windows[booking.index] = 2
            break
        }
    }
}

function getStyle(window) {
    if (window === 1) {
        return 'window-available'
    } else if (window === 2) {
        return 'window-current'
    } else if (window === 3) {
        return 'window-selected'
    } else {
        return 'window-unavailable'
    }
}

function getCalendarText(window) {
    if (window === 1) {
        return 'LEDIG'
    } else if (window === 2) {
        return 'BOKAD'
    } else if (window === 3) {
        return 'ÖNSKAD'
    } else {
        return ''
    }
}

</script>

<style scoped>
h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #f7b107;
}
.logo {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 300px;
}
.user-info {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px solid #000;
    border-radius: 5px;
    width: 30rem;
}
.btn-primary {
    background-color: #0664ae;
    border-color: #2381c9;
}
.btn-primary:hover {
    background-color: #004a83;
    border-color: #0f61a0;
}
.btn-primary:disabled {
    background-color: #777;
    color: #ddd;
    border-color: #ccc;
}
.booking-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.booking-date-title {
    color: #777;
    margin-top: 1rem;
}
.booking-date {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}
.calendar-row {
    border-top: 1px solid #000;
}
.window-current {
    padding: 0.75rem 0 0 0;
    background-color: #f7b107;
    color: #000;
    height: 3rem;
    border-left: 10px solid #fff;
    border-top: 1px solid #000;
}
.window-available {
    padding: 0.75rem 0 0 0;
    background-color: #0776ca;
    color: #fff;
    font-weight: bold;
    height: 3rem;
    border-left: 10px solid #fff;
    border-top: 1px solid #000;
}
.window-available:hover {
    background-color: #004a83;
}
.window-unavailable {
    background-color: #ccc;
    color: #000;
    height: 3rem;
    border-left: 10px solid #fff;
    border-top: 1px solid #000;
}
.window-selected {
    padding: 0.75rem 0 0 0;
    background-color: #14f014;
    color: #000;
    height: 3rem;
    border-left: 10px solid #fff;
    border-top: 1px solid #000;
}
.week {
    font-size: 1.2rem;
    color: #777;
}
.week-row {
    margin-bottom: 1rem;
}
.confirm-icon {
    margin-top: 0.5rem;
    color: #f7b107;
}
</style>