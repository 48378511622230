class DateTime {
    addDays(date, days) {
        const result = new Date(date)
        result.setDate(result.getDate() + days)
        return result
    }

    getWeekNumber(date) {
        const MS_A_DAY = 1000 * 60 * 60 * 24
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay()||7))
        var yearStart = new Date(Date.UTC(date.getUTCFullYear(),0,1))
        var weekNo = Math.ceil(( ( (date - yearStart) / MS_A_DAY) + 1)/7)
        return weekNo
    }

    getWeeksInYear(year) {
        const week = this.getWeekNumber(new Date(year, 11, 31))
        return week == 1 ? 52 : week
    }

    getMonday(year, week) {
        const jan4 = new Date(year, 0, 4)
        const date = this.addDays(jan4, (week - 1) * 7)
        const day = (date.getDay() + 6) % 7
        return this.addDays(date, -day)
    }

    getMonthString(month) {
        const monthNames = [ 'jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec' ]
        return monthNames[month]
    }
}

export default new DateTime()